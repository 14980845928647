import './index.scss'

document.querySelector('.nav__title').classList.add('nav_switch')

document.querySelector('.nav__title').classList.add('active')

document.querySelector('.nav').addEventListener('mouseenter', () => {
    document.querySelector('.nav').classList.add('active')
    document.querySelector('.nav_items').classList.add('active')
    document.querySelector('.nav__title').classList.remove('active')
})
document.querySelector('.nav').addEventListener('mouseleave', () => {
    document.querySelector('.nav').classList.remove('active')
    document.querySelector('.nav_items').classList.remove('active')
    document.querySelector('.nav__title').classList.add('active')
})

window.addEventListener('resize', () => {
    if (document.documentElement.clientWidth < 992) {
        document.querySelector('.nav').classList.remove('active')
        document.querySelector('.nav_items').classList.remove('active')
    }
})

window.addEventListener('scroll', function () {

    let category_btn = document.querySelector('.nav_switch')
    let nav_items = document.querySelector('.nav_items')
    let y = window.scrollY;
    let x = document.documentElement.clientWidth

    if (y > 50 && x > 992) {
        category_btn.classList.add('fixed')
        nav_items.classList.add('fixed')
    } 
    else {
        category_btn.classList.remove('fixed')
        nav_items.classList.remove('fixed')
    }
});